import React from "react"
import Header from '../components/Header.js'
import styled from 'styled-components'
import LinkTo from '../components/LinkTo'
import Transition from '../components/Transition.js'

const Copyright = styled.p`
    flex-basis: 100%;
    margin-top: 70px;
    text-align: center;
    font-size: 13px;
    font-weight: 300;

    @media screen and (max-width: 750px) {
        margin: 75px 0 10px;
        font-size: 12px !important;
    }
`
const FooterTag = styled.footer`
    display: flex;
    flex-direction: column;
    margin: 0 2.43vw 10px;
    flex-wrap: wrap;

    @media screen and (max-width: 750px) {
        margin: 0 4vw 10px;
    }
`

const LinksDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 160px;
    min-height: 160px;

    @media screen and (max-width: 750px) {
        height: auto;
        min-height: none;
        align-items: flex-start;

        a {
            margin-bottom: 35px;
        }

        a:last-child {
            margin-bottom: 0;
        }
    }
`

const currentYear = new Date().getFullYear();

const LayoutWithoutFooter = ({ children, headerPosition, footerLinksArray, location }) => (
    <div className="max-width-1600">
        <Header siteTitle="ANDREA BLAHOVÁ" headerPosition={headerPosition}></Header>
        <Transition location={location}>
            {children} 
            <FooterTag>
                <LinksDiv>
                    {footerLinksArray.map(footerLink => (
                        <LinkTo linkURL={footerLink.URL} linkName={footerLink.name} key={footerLink.name}></LinkTo>
                    ))}
                </LinksDiv>
                <Copyright>Copyright © {currentYear}. Andrea Blahová</Copyright>
            </FooterTag>
        </Transition>
    </div>
)

export default LayoutWithoutFooter
