import React from "react"
import styled from "styled-components"

const H1Tag = styled.h1`
    font-size: 22px;
    color: #000;
    letter-spacing: 2.7px;

    @media screen and (min-width: 1600px) {
        font-size: 24px;
    }

    @media screen and (max-width: 1100px) {
        font-size: 20px;
    }
`
const PTag = styled.p`
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 18px;
    line-height: 26px;
    margin-top: 20px;
    text-align: justify;

    @media screen and (min-width: 1600px) {
        font-size: 20px;
    }

    @media screen and (max-width: 1100px) {
        font-size: 16px;
    }
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 96px 2.43vw 0px;
    width: calc( 50% - 2.43vw );

    @media screen and (max-width: 1100px) {
        width: calc( 66.6% - 2.43vw );
    }

    @media screen and (max-width: 750px) {
        margin: 75px 4vw 0;
        width: calc( 100% - 8vw );
    }
`

const SubpageHeader = ({ headline, introTextArray, containerStyle }) => {
    
    const paragraphs = () => {
        if(introTextArray != null) {
            return introTextArray.map(paragraph => <PTag key={paragraph.text}>{paragraph.text}</PTag>)
        } return <PTag></PTag>
    }
    

    return (
        <>
            <ContentContainer style={ containerStyle }>
                <H1Tag>{headline.toUpperCase()}</H1Tag>
                {paragraphs()}
            </ContentContainer>
        </>
    )
}

export default SubpageHeader