import React, { useRef, useLayoutEffect, useEffect, useState } from "react"
import { graphql } from "gatsby"
import LayoutWithoutFooter from '../layouts/LayoutWithoutFooter'
import SubpageHeader from '../components/SubpageHeader'
import styled from 'styled-components'
import Img from 'gatsby-image'

export const query = graphql`
    {
        prismic {
            allAbouts {
                edges {
                    node {
                        short_footer_text
                        about_text
                        email
                        instagram_username
                        photo_desktop
                        photo_desktopSharp {
                            childImageSharp {
                                fluid(maxWidth: 780, quality: 100) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        photo_mobile
                        photo_mobileSharp {
                            childImageSharp {
                                fluid(maxWidth: 900, quality: 100) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
const ContactH1 = styled.h1`
    font-size: 22px;
    color: #000;
    letter-spacing: 2.7px;
    margin-bottom: 20px;

    @media screen and (min-width: 1600px) {
        font-size: 24px;
    }

    @media screen and (max-width: 1100px) {
        font-size: 20px;
    }
`

const MainContainer = styled.div`
    display: flex;
    margin-bottom: 10vw;

    @media screen and (max-width: 900px) {
        flex-direction: column;
    }

    @media screen and (max-width: 750px) {
        margin-bottom: 100px;
    }
`

const TextContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1100px) {
        flex: 1.25;
    }

    @media screen and (max-width: 900px) {
        order: 2;      
    }
`

const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 75px 2.43vw 0;

    @media screen and (max-width: 750px) {
        margin: 75px 4vw 0;
    }
`

const ImgContainer = styled.div`
    flex: 1;
    margin-top: 143px;
    margin-right: 56px;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;

    @media screen and (max-width: 900px) {
        margin-right: 0;
        margin-top: 0;
    }
`
const ImgTag = styled(Img)`
    width: 100%;
    
    @media screen and (max-width: 900px) {
        max-height: 30vh !important;
    }
`

const LinkTag = styled.a`
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 18px;
    text-decoration: none;
    color: #000;

    @media screen and (min-width: 1600px) {
        font-size: 20px;
    }

    @media screen and (max-width: 1100px) {
        font-size: 16px !important;
    }
`

const LinkTagSpan = styled.span`
    font-weight: 400;
    margin-left: 13px;

    @media screen and (max-width: 750px) {
        margin-left: 0;
    }
`

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: repeat(2,1fr);
    grid-column-gap: 5vw;
    grid-row-gap: 5vw;
    max-width: 70vw;
    margin: 56px auto;
    align-items: center;

    @media screen and (max-width: 1100px) {
        max-width: 75vw;
    }

    @media screen and (max-width: 900px) {
        max-width: 80vw;
    }

    @media screen and (max-width: 750px) {
        max-width: none;
        margin: 35px 4vw 75px;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: auto;
        grid-column-gap: 4vw;
        grid-row-gap: 4vw;
    }
`
const InstagramLink = styled.a`
    text-decoration: none;
    color: #000;
    font-size: 20px;
    letter-spacing: 2px;

    @media screen and (min-width: 1600px) {
        font-size: 22px;
    }

    @media screen and (max-width: 1100px) {
        font-size: 18px;
    }

    &:after {
        margin-left: 15px;
        content: "→";
        transform-origin: 50% 50%;
        display: inline-block;
        transition: transform .2s ease-out;
        font-family: 'KontrapunktMiki-Regular';
        font-size: 18px;

        @media screen and (min-width: 1600px) {
            font-size: 20px;
        }

        @media screen and (max-width: 1100px) {
            font-size: 16px;
        }
    }

    &:hover:after {
        transform: translateX(8px);
    }
`

const InstagramLinkContainer = styled.div`
    text-align: center;

    @media screen and (max-width: 750px) {
        text-align: left;
        margin: 0 4vw;
    }
`

const InstagramPostLink = styled.a`
    @media screen and (max-width: 750px) {
        height: 100%;
        max-height: 100%
    }
`

const About = ({ data, location }) => {
    // reference to text column on the left side. 
    // Getting height of the column and setting max-height of
    // the image on the right side so it is not too big
    const aboutTextRef = useRef();
    const [windowWidth, setWindowWidth] = useState(0);
    const [aboutTextSize, setAboutTextSize] = useState(0);

    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    useLayoutEffect(() => {
        if (aboutTextRef.current) {
            setAboutTextSize(aboutTextRef.current.offsetHeight);
        }
    }, []);

    const aboutData = data.prismic.allAbouts.edges.slice(0, 1).pop()
    if (!aboutData) return null

    return (
        <>
            {/* <SEO title="Projects" /> */}
            <LayoutWithoutFooter
                location={location}
                headerPosition={ windowWidth <= 900 ? "absolute" : "static" }
                footerLinksArray={[      
                    {
                        name: 'Home',
                        URL: '/'
                    },
                    {
                        name: 'Projects',
                        URL: '/projects/'
                    },
                    {
                        name: 'Events',
                        URL: '/events/'
                    },
                    {
                        name: 'Diary',
                        URL: '/diary/'
                    },
                ]}>
                <MainContainer>
                    <TextContainer ref={aboutTextRef}>
                            <SubpageHeader 
                                headline={"ABOUT"} 
                                introTextArray={aboutData.node.about_text} 
                                containerStyle={
                                    { 
                                        width: 'auto',
                                        marginTop: windowWidth <= 900 ? "75px" : "96px"
                                    }}>
                            </SubpageHeader>

                            {windowWidth >= 375 && 
                                <ContactContainer>
                                    <ContactH1>CONTACT</ContactH1>
                                    <div style={{ marginBottom: "16px" }}>
                                        <LinkTag href={`mailto:${aboutData.node.email[0].text}`}>
                                            Email: <LinkTagSpan>{aboutData.node.email[0].text}</LinkTagSpan>
                                        </LinkTag>
                                    </div>
                                    <div>
                                        <LinkTag target="_blank" rel="noopener noreferrer" href={`http://instagram.com/_u/${aboutData.node.instagram_username[0].text}/`}>
                                            Instagram: <LinkTagSpan>@{aboutData.node.instagram_username[0].text}</LinkTagSpan>
                                        </LinkTag>
                                    </div>
                                </ContactContainer>
                            }
                            {windowWidth < 375 && 
                                <ContactContainer>
                                    <ContactH1>CONTACT</ContactH1>
                                    <div style={{ marginBottom: "16px" }}>
                                        <LinkTag href={`mailto:${aboutData.node.email[0].text}`}>
                                            <LinkTagSpan>{aboutData.node.email[0].text}</LinkTagSpan>
                                        </LinkTag>
                                    </div>
                                    <div>
                                        <LinkTag target="_blank" rel="noopener noreferrer" href={`http://instagram.com/_u/${aboutData.node.instagram_username[0].text}/`}>
                                            <LinkTagSpan>@{aboutData.node.instagram_username[0].text}</LinkTagSpan>
                                        </LinkTag>
                                    </div>
                                </ContactContainer>
                            }
                    </TextContainer>
                    <ImgContainer>
                        {windowWidth > 900 && 
                            // the correct amount of pixels the photo is distanced from the top is -105 but I used -50, bcs it looks better 
                            <ImgTag fluid={aboutData.node.photo_desktopSharp.childImageSharp.fluid} style={{ maxHeight: `${aboutTextSize-50}px` }}></ImgTag>
                        }
                        {windowWidth <= 900 && 
                            <ImgTag fluid={aboutData.node.photo_mobileSharp.childImageSharp.fluid}></ImgTag>
                        }
                    </ImgContainer>
                </MainContainer>
            </LayoutWithoutFooter>
        </>
    )
}

export default About
